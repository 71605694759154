import React from "react"
import Membership from "../components/Membership/membership"
import Layout from "../components/layout"
import Seo from "../components/seo"

const links = [
  { displayName: "Home", link: "/" },
  { displayName: "About", link: "/#about" },
  { displayName: "Contact", link: "/#contact" },
]
const MembershipPage = () => (
  <Layout navigationLinks={links}>
    <Seo title="Membership" />
    <main
      className={"mt-5"}
      id={"main"}
      style={{
        background: "url('../images/hero-bg.jpg') top center",
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <Membership />
    </main>
  </Layout>
)

export default MembershipPage
